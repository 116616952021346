<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      lazy
      static
    >
      <div>
        <div v-if="selectSku">
          <div>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6">
                <h4>SKU: {{ selectSku.sku }}</h4>
              </b-col>
            </b-row>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6 d-flex">
                <p><strong><u>Delay of shipping:</u></strong></p>
                <div class="">
                  <b-form-input
                    v-model="selectSku.delay"
                    placeholder="Number of days"
                    style="height: auto;"
                    class="border-0 shadow-none"
                  />
                  <!-- <p>days</p> -->
                </div>
              </b-col>
              <b-col cols="6 d-flex">
                <p><strong><u>Stock Limit (minimum stock for order):</u></strong></p>
                <div class="">
                  <b-form-input
                    v-model="selectSku.stockLimit"
                    placeholder="Minimum stock"
                    style="height: auto;"
                    class="border-0 shadow-none"
                  />
                  <!-- <p>days</p> -->
                </div>
              </b-col>
              <b-col cols="6">
                <p><strong><u>Locked Stock Update:</u></strong></p>
                <div class="">
                  <flat-pickr
                    v-model="selectSku.dateStockUpdate"
                    placeholder="Choisissez une date"
                    class="form-control mr-1"
                  />
                </div>
              </b-col>
              <b-col cols="6">
                <p><strong><u>Compose sku:</u></strong></p>
                <div class="">
                  <b-form-input
                    v-model="selectSku.composeSku"
                    placeholder="Sku composé (exemple: COMPOSANT1,COMPOSANT2)"
                    style="height: auto;"
                    class="border-0 shadow-none"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6">
                <p><strong><u>Dispatch Rules:</u></strong>
                  <v-select
                    v-model="selectSku.logCategory"
                    :options="getRulesList()"
                    transition=""
                  />
                </p>
                <div v-for="(dispatch, keyD) of rules.filter(r => r.category === selectSku.logCategory)" :key="keyD">
                  <p><strong><u>Countries:</u></strong> {{ dispatch.country.join(' / ') }}</p>
                  <ul>
                    <li v-for="(solution, keyS) of dispatch.solutions" :key="keyS">
                      <strong><u>Log:</u></strong> {{ solution.logistic }} / <strong><u>Shipper:</u></strong> {{ solution.transport }} / <strong><u>Level:</u></strong> {{ solution.level }}
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
            <b-row class="breadcrumbs-top mb-2">
              <b-button :disabled="loading" @click="save">
                Enregistrer
              </b-button>
            </b-row>
          </div>
        </div>

      </div>
    </b-modal>
    <b-card>
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          filterable
          :items="logistics"
          :fields="fields"
          @row-clicked="openModal"
        >

          <template #cell(logCategory)="data">
            <p>
              {{ data.item.logCategory }}
            </p>
          </template>
          <!-- <template #cell(disableError)="data">
            <feather-icon
              icon="TrashIcon"
              size="14"
              @click="logistics = logistics.filter((item) => item.number !== data.item.number)"
            />
          </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  // BBadge,
  BModal,
  BTable,
  VBPopover,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    flatPickr,
    // BBadge,
    BInputGroup,
    BModal,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseLogistics: [],
      logistics: [],
      products: [],
      categories: [],
      logistic: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'sku' }, { key: 'composeSku' }, { key: 'logCategory', sortable: true }, { key: 'delay', sortable: true }, { key: 'stockLimit', sortable: true }],
      showModal: false,
      selectSku: null,
      categoryInformations: [],
      rules: null,
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getLogistics()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    openModal(element) {
      console.log(element)
      this.selectSku = element
      this.showModal = true
    },
    getRulesList() {
      const categories = this.rules.map(r => r.category)
      return [...new Set(categories)]
    },
    async save() {
      try {
        this.loading = true
        await this.$http.post(`/admin/logistic/${this.selectSku._id}/dispatch`, { log: this.selectSku })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien sauvegardée.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showModal = false
        this.selectSku = null
        // this.getLogistics()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getLogistics() {
      try {
        const {
          logistics, rules,
        } = await this.$http.get('/admin/logistic/details')
        this.logistics = logistics
        this.rules = rules
        this.baseLogistics = logistics
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.logistics = this.baseLogistics
      } else {
        const value = this.code
        console.log(value)
        this.logistics = this.baseLogistics.filter(logistic => logistic.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
